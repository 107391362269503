
import React, { useEffect, useState } from 'react';
import './../../../App.css';
import { useLocation } from 'react-router-dom';
import chatConfigApi from '../../../services/ChatConfigApi';
import TrashIcon from '../../../icons/TrashIcon';
import EmojiPicker from '../../../components/Utils/EmojiPicker';
import PlusIcon from '../../../icons/PlusIcon';
import Modal from '../../../components/Modal/Modal';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

function BotConfig(...props: any) {

  var [ShowSteps, setShowSteps] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [steps, setSteps] = useState([] as any);
  var [instancia, setInstancia] = useState(0);
  var [option1, setOption1] = useState("");
  var [option2, setOption2] = useState("");
  var [option3, setOption3] = useState("");
  var [option4, setOption4] = useState("");
  var [option5, setOption5] = useState("");
  var [option6, setOption6] = useState("");
  var [option7, setOption7] = useState("");
  var [option8, setOption8] = useState("");
  var [option9, setOption9] = useState("");
  var [option10, setOption10] = useState("");

  var [redirecionarSequenciaCampo1, setRedirecionarSequenciaCampo1] = useState(null);
  var [redirecionarSequenciaCampo2, setRedirecionarSequenciaCampo2] = useState(null);
  var [redirecionarSequenciaCampo3, setRedirecionarSequenciaCampo3] = useState(null);
  var [redirecionarSequenciaCampo4, setRedirecionarSequenciaCampo4] = useState(null);
  var [redirecionarSequenciaCampo5, setRedirecionarSequenciaCampo5] = useState(null);
  var [redirecionarSequenciaCampo6, setRedirecionarSequenciaCampo6] = useState(null);
  var [redirecionarSequenciaCampo7, setRedirecionarSequenciaCampo7] = useState(null);
  var [redirecionarSequenciaCampo8, setRedirecionarSequenciaCampo8] = useState(null);
  var [redirecionarSequenciaCampo9, setRedirecionarSequenciaCampo9] = useState(null);
  var [redirecionarSequenciaCampo10, setRedirecionarSequenciaCampo10] = useState(null);

  const [editing, setEditing] = useState<'response' | 'inicio' | 'fim'>('response');
  var [mensagemsInicio, setMensagemInicio] = useState("");
  var [mensagemsFim, setMensagemFim] = useState("");
  var [mensagemErro, setMensagemErro] = useState("");
  const [nomeDepartamento, setNomeDepartamento] = useState("");
  const [dataHoraInicio, setDataHoraInicio] = useState("");
  const [dataHoraFim, setDataHoraFim] = useState("");
  const [titulos, setTitulos] = useState("");
  const [showOption, setShowOption] = useState(false);
  const [showAtendimento, setShowAtendimento] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [stepsSelected, setStepsSelected] = useState([] as any);
  const [departamentoSelected, setDepartamentoSelected] = useState({} as any);
  const [departamentos, setDepartamentos] = useState({} as any);
  var [countOptions, setCountOptions] = useState(0);
  const [countStep, setCountStep] = useState<number>(0);

  const handleRemoveStep = (data: any) => {

    chatConfigApi.deleteBotConfig(data.id).then((r) => {
      chatConfigApi.listBotConfigs({
        "filters": {
          "codUserInstance": instancia
        }
      }).then((response) => {
        setSteps(response);
        handleClickSelectedStep([]);
      });
    })

  }

  const handleUpdateDepartamento = () => {

    const updateCategoriaAtendente = {
      'setor': nomeDepartamento,
      'horaInicio': dataHoraInicio,
      'horaFim': dataHoraFim,
    }

    chatConfigApi.updateCategoriaAtendente(
      departamentoSelected['id'], updateCategoriaAtendente
    ).then((response) => {
      console.log(response);
      chatConfigApi.listCategoriaAtendente({
        "filters": {
          "codUserInstance": instancia
        }
      }).then((response) => {
        setDepartamentoSelected({});
        setDepartamentos(response);
        setNomeDepartamento('');
        setDataHoraFim('');
        setDataHoraInicio('');
      }); 
    });
  }

  const handleAddDepartamento = () => {

    chatConfigApi.createCategoriaAtendente({
      'setor': nomeDepartamento,
      'horaInicio': dataHoraInicio,
      'horaFim': dataHoraFim,
      "codUserInstance": instancia
    }).then((response) => {
      chatConfigApi.listCategoriaAtendente({
        "filters": {
          "codUserInstance": instancia
        }
      }).then((response) => {
        setDepartamentos(response);
        setNomeDepartamento('');
        setDataHoraFim('');
        setDataHoraInicio('');
      }); 
    });
  }


  const handleRemoveOption = () => {

    if (countOptions > 1) {
      setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      if (countOptions - 1 === 0) {
        setShowOption(false);
        setOption1("");
        setOption1("");
        setRedirecionarSequenciaCampo1(null);
      }
      else if (countOptions - 1 === 1) {
        setOption2("");
        setRedirecionarSequenciaCampo2(null);
      }
      else if (countOptions - 1 === 2) {
        setOption3("");
        setRedirecionarSequenciaCampo3(null);
      }
      else if (countOptions - 1 === 3) {
        setOption4("");
        setRedirecionarSequenciaCampo4(null);
      }
      else if (countOptions - 1 === 4) {
        setOption5("");
        setRedirecionarSequenciaCampo5(null);
      }
      else if (countOptions - 1 === 5) {
        setOption6("");
        setRedirecionarSequenciaCampo6(null);
      }
      else if (countOptions - 1 === 6) {
        setOption7("");
        setRedirecionarSequenciaCampo7(null);
      }
      else if (countOptions - 1 === 7) {
        setOption8("");
        setRedirecionarSequenciaCampo8(null);
      }
      else if (countOptions - 1 === 8) {
        setOption9("");
        setRedirecionarSequenciaCampo9(null);
      }
      else if (countOptions - 1 === 9) {
        setOption10("");
        setRedirecionarSequenciaCampo10(null);
      }
      setCountOptions(countOptions - 1);
    }

  }

  const handleAtendimento = () => {
    if (showAtendimento) {
      setShowAtendimento(false);
    }
    else {
      setShowAtendimento(true);
      setShowOption(false);
    }
  }

  const handleCheckBoxAtendimento = () => {
    if (showOption === true) {
      setShowOption(false);
      setCountOptions(0);
      setOption1("");
      setOption2("");
      setOption3("");
      setOption4("");
      setOption5("");
      setOption6("");
      setOption7("");
      setOption8("");
      setOption9("");
      setOption10("");
      setRedirecionarSequenciaCampo1(null);
      setRedirecionarSequenciaCampo2(null);
      setRedirecionarSequenciaCampo3(null);
      setRedirecionarSequenciaCampo4(null);
      setRedirecionarSequenciaCampo5(null);
      setRedirecionarSequenciaCampo6(null);
      setRedirecionarSequenciaCampo7(null);
      setRedirecionarSequenciaCampo8(null);
      setRedirecionarSequenciaCampo9(null);
      setRedirecionarSequenciaCampo10(null);
    }
    else {
      setShowOption(true);
      setShowAtendimento(false);
      setCountOptions(1);
    }
  }

  const handleClickOption1 = (data: any) => {
    setOption1(data.titulo);
    setRedirecionarSequenciaCampo1(data.id);
  }

  const handleClickOption2 = (data: any) => {
    setOption2(data.titulo);
    setRedirecionarSequenciaCampo2(data.id);
  }

  const handleClickOption3 = (data: any) => {

    setOption3(data.titulo);
    setRedirecionarSequenciaCampo3(data.id);
  }

  const handleClickOption4 = (data: any) => {

    setOption4(data.titulo);
    setRedirecionarSequenciaCampo4(data.id);
  }

  const handleClickOption5 = (data: any) => {

    setOption5(data.titulo);
    setRedirecionarSequenciaCampo5(data.id);
  }

  const handleClickOption6 = (data: any) => {

    setOption6(data.titulo);
    setRedirecionarSequenciaCampo6(data.id);
  }

  const handleClickOption7 = (data: any) => {

    setOption7(data.titulo);
    setRedirecionarSequenciaCampo7(data.id);
  }

  const handleClickOption8 = (data: any) => {

    setOption8(data.titulo);
    setRedirecionarSequenciaCampo8(data.id);
  }

  const handleClickOption9 = (data: any) => {

    setOption9(data.titulo);
    setRedirecionarSequenciaCampo9(data.id);
  }

  const handleClickOption10 = (data: any) => {

    setOption10(data.titulo);
    setRedirecionarSequenciaCampo10(data.id);
  }


  const handleCountOption = () => {

    if (countOptions < 10) {
      if (countOptions === 1) {
        if (option1 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 2) {
        if (option1 !== "" && option2 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 3) {
        if (option1 !== "" && option2 !== "" && option3 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 4) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 5) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 6) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "" && option6 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 7) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "" && option6 !== "" && option7 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 8) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "" && option6 !== "" && option7 !== "" && option8 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 9) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "" && option6 !== "" && option7 !== "" && option8 !== "" && option9 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 10) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "" && option6 !== "" && option7 !== "" && option8 !== "" && option9 !== "" && option10 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
    }
  }

  const handleRemoveDepartament = (data: any) => {

    chatConfigApi.deleteCategoriaAtendente(data.id).then((r : any) =>{ 
      
      chatConfigApi.listCategoriaAtendente({
      "filters": {
        "codUserInstance": instancia
      }
    }).then((response) => {
      setDepartamentos(response);
      setDepartamentoSelected({});
      setNomeDepartamento('');
      setDataHoraInicio('');
      setDataHoraFim('');
    });
  })
  }

  const handleClickSelectedStep = (data: any) => {
    if (data.length === 0) {

      setMensagemInicio("");
      setMensagemFim("");
      setMensagemErro("");
      setTitulos("");
      setShowOption(false);
      setShowAtendimento(false);
      setCountOptions(0);
      setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      setOption1("");
      setOption2("");
      setOption3("");
      setOption4("");
      setOption5("");
      setOption6("");
      setOption7("");
      setOption8("");
      setOption9("");
      setOption10("");
      setRedirecionarSequenciaCampo1(null);
      setRedirecionarSequenciaCampo2(null);
      setRedirecionarSequenciaCampo3(null);
      setRedirecionarSequenciaCampo4(null);
      setRedirecionarSequenciaCampo5(null);
      setRedirecionarSequenciaCampo6(null);
      setRedirecionarSequenciaCampo7(null);
      setRedirecionarSequenciaCampo8(null);
      setRedirecionarSequenciaCampo9(null);
      setRedirecionarSequenciaCampo10(null);

    }
    else {
      setMensagemInicio("");
      setMensagemFim("");
      setMensagemErro("");
      setTitulos("");
      setShowOption(false);
      setShowAlert(false);
      setCountOptions(0);
      setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      setOption1("");
      setOption2("");
      setOption3("");
      setOption4("");
      setOption5("");
      setOption6("");
      setOption7("");
      setOption8("");
      setOption9("");
      setOption10("");
      setRedirecionarSequenciaCampo1(null);
      setRedirecionarSequenciaCampo2(null);
      setRedirecionarSequenciaCampo3(null);
      setRedirecionarSequenciaCampo4(null);
      setRedirecionarSequenciaCampo5(null);
      setRedirecionarSequenciaCampo6(null);
      setRedirecionarSequenciaCampo7(null);
      setRedirecionarSequenciaCampo8(null);
      setRedirecionarSequenciaCampo9(null);
      setRedirecionarSequenciaCampo10(null);

      setMensagemInicio(data.mensagemInicio === null ? "" : data.mensagemInicio);
      setMensagemFim(data.mensagemFim === null ? "" : data.mensagemFim);
      setMensagemErro(data.mensagemErro === null ? "" : data.mensagemErro);
      setShowOption(data.opcao === 0 ? false : true);
      setShowAtendimento(data.filaAtendimento === 0 ? false : true)
      setTitulos(data.titulo);
      setCountOptions(0);

      setNomeDepartamento('');
      setDataHoraInicio('');
      setDataHoraFim('');

      var count = 0;
      if (data.campo1 !== null) {
        setOption1(data.campo1);
        count++;
        setRedirecionarSequenciaCampo1(data.redirecionarSequenciaCampo1);
      }
      else {
        setOption1("");
        setRedirecionarSequenciaCampo1(null);
      }
      if (data.campo2 !== null) {
        setOption2(data.campo2);
        count++;
        setRedirecionarSequenciaCampo2(data.redirecionarSequenciaCampo2);
      }
      else {
        setOption2("");
        setRedirecionarSequenciaCampo2(null);
      }
      if (data.campo3 !== null) {
        setOption3(data.campo3);
        count++;
        setRedirecionarSequenciaCampo3(data.redirecionarSequenciaCampo3);
      }
      else {
        setOption3("");
        setRedirecionarSequenciaCampo3(null);
      }
      if (data.campo4 !== null) {
        setOption4(data.campo4);
        count++;
        setRedirecionarSequenciaCampo4(data.redirecionarSequenciaCampo4);
      }
      else {
        setOption4("");
        setRedirecionarSequenciaCampo4(null);
      }
      if (data.campo5 !== null) {
        setOption5(data.campo5);
        count++;
        setRedirecionarSequenciaCampo5(data.redirecionarSequenciaCampo5);
      }
      else {
        setOption5("");
        setRedirecionarSequenciaCampo5(null);
      }
      if (data.campo6 !== null) {
        setOption6(data.campo6);
        count++;
        setRedirecionarSequenciaCampo6(data.redirecionarSequenciaCampo6);
      }
      else {
        setOption6("");
        setRedirecionarSequenciaCampo6(null);
      }
      if (data.campo7 !== null) {
        setOption7(data.campo7);
        count++;
        setRedirecionarSequenciaCampo7(data.redirecionarSequenciaCampo7);
      }
      else {
        setOption7("");
        setRedirecionarSequenciaCampo7(null);
      }
      if (data.campo8 !== null) {
        setOption8(data.campo8);
        count++;
        setRedirecionarSequenciaCampo8(data.redirecionarSequenciaCampo8);
      }
      else {
        setOption8("");
        setRedirecionarSequenciaCampo8(null);
      }
      if (data.campo9 !== null) {
        setOption9(data.campo9);
        count++;
        setRedirecionarSequenciaCampo9(data.redirecionarSequenciaCampo9);
      }
      else {
        setOption9("");
        setRedirecionarSequenciaCampo9(null);
      }
      if (data.campo10 !== null) {
        setOption1(data.campo10);
        count++;
        setRedirecionarSequenciaCampo10(data.redirecionarSequenciaCampo10);
      }
      else {
        setOption10("");
        setRedirecionarSequenciaCampo10(null);
      }

      setCountOptions(count);
    }

    setStepsSelected(data);
  }

  const handleChangeInicio = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const originalText = event.target.value;
    const wrappedText = wrapText(originalText, 32);
    setMensagemInicio(wrappedText);

  }

  const handleChangeFim = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const originalText = event.target.value;
    const wrappedText = wrapText(originalText, 32);
    setMensagemFim(wrappedText);

  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (editing === 'inicio') {
        setMensagemInicio(prevMensagem => {
          const newMessage = prevMensagem + '\n';
          return wrapText(newMessage, 32);
        });
      } else if (editing === 'fim') {
        setMensagemFim(prevMensagem => {
          const newMessage = prevMensagem + '\n';
          return wrapText(newMessage, 32);
        });
      }
    }
  };

  const onEmojiSelectInicio = (emoji: any) => {
    setMensagemInicio(mensagemsInicio + emoji);
  };

  const onEmojiSelectFim = (emoji: any) => {
    setMensagemFim(mensagemsFim + emoji);
  };
  const onEmojiSelectErro = (emoji: any) => {
    setMensagemErro(mensagemErro + emoji);
  };


  const wrapText = (text: string, maxLineLength: number): string => {
    let wrappedText = '';
    let currentLineLength = 0;

    for (let i = 0; i < text.length; i++) {
      if (currentLineLength >= maxLineLength && text[i] !== '\n') {
        wrappedText += '\n';
        currentLineLength = 0;
      }
      wrappedText += text[i];
      currentLineLength++;

      if (text[i] === '\n') {
        currentLineLength = 0;
      }
    }

    return wrappedText;
  };

  const renderCampo = (campo: string, index: number) => {
    return campo !== null && campo !== "" ? <p className="text-sm">{index + 1} - {campo}</p> : null;
  };

  const handleSave = () => {

    console.log(countOptions);

    if (stepsSelected.length !== 0) {
      const updateConfigData = {
        titulo: titulos,
        codUserInstance: instancia,
        enviarDocumento: false,
        filaAtendimento: showAtendimento,
        opcao: showOption,
        campo1: option1,
        campo2: option2,
        campo3: option3,
        campo4: option4,
        campo5: option5,
        campo6: option6,
        campo7: option7,
        campo8: option8,
        campo9: option9,
        campo10: option10,
        redirecionarSequenciaCampo1: redirecionarSequenciaCampo1,
        redirecionarSequenciaCampo2: redirecionarSequenciaCampo2,
        redirecionarSequenciaCampo3: redirecionarSequenciaCampo3,
        redirecionarSequenciaCampo4: redirecionarSequenciaCampo4,
        redirecionarSequenciaCampo5: redirecionarSequenciaCampo5,
        redirecionarSequenciaCampo6: redirecionarSequenciaCampo6,
        redirecionarSequenciaCampo7: redirecionarSequenciaCampo7,
        redirecionarSequenciaCampo8: redirecionarSequenciaCampo8,
        redirecionarSequenciaCampo9: redirecionarSequenciaCampo9,
        redirecionarSequenciaCampo10: redirecionarSequenciaCampo10,
        mensagemInicio: mensagemsInicio,
        mensagemFim: mensagemsFim,
        mensagemErro: mensagemErro
      };


      chatConfigApi.updateBotConfig(stepsSelected.id, updateConfigData).then((r) => {
        chatConfigApi.listBotConfigs({
          "filters": {
            "codUserInstance": instancia
          }
        }).then((response) => {
          setSteps(response);
        });
      });
    }
    else {
      const botConfigData = {
        titulo: titulos,
        codUserInstance: instancia,
        enviarDocumento: false,
        filaAtendimento: showAtendimento,
        opcao: showOption,
        campo1: option1,
        campo2: option2,
        campo3: option3,
        campo4: option4,
        campo5: option5,
        campo6: option6,
        campo7: option7,
        campo8: option8,
        campo9: option9,
        campo10: option10,
        redirecionarSequenciaCampo1: redirecionarSequenciaCampo1,
        redirecionarSequenciaCampo2: redirecionarSequenciaCampo2,
        redirecionarSequenciaCampo3: redirecionarSequenciaCampo3,
        redirecionarSequenciaCampo4: redirecionarSequenciaCampo4,
        redirecionarSequenciaCampo5: redirecionarSequenciaCampo5,
        redirecionarSequenciaCampo6: redirecionarSequenciaCampo6,
        redirecionarSequenciaCampo7: redirecionarSequenciaCampo7,
        redirecionarSequenciaCampo8: redirecionarSequenciaCampo8,
        redirecionarSequenciaCampo9: redirecionarSequenciaCampo9,
        redirecionarSequenciaCampo10: redirecionarSequenciaCampo10,
        mensagemInicio: mensagemsInicio,
        mensagemFim: mensagemsFim,
        mensagemErro: mensagemErro,
      };

      chatConfigApi.createBotConfig(botConfigData).then((r) => {
        chatConfigApi.listBotConfigs({
          "filters": {
            "codUserInstance": instancia
          }
        }).then((response) => {
          setSteps(response);
        });
      });
    }


  };

  const renderSteps = (steps: any, handleClickSelectedStep: any) => {
    const renderedSteps = [] as JSX.Element[];
    const processedIds = new Set<number>();

    const processStep = (stepId: number, path: string) => {
      if (processedIds.has(stepId)) return;
      const step = steps.find((s: any) => s.id === stepId);
      if (!step) return;

      processedIds.add(stepId);

      const nextSteps = Array.from({ length: 10 }, (_, i) => {
        const nextStepId = step[`redirecionarSequenciaCampo${i + 1}`];
        if (nextStepId) {
          const nextStep = steps.find((s: any) => s.id === nextStepId);
          if (nextStep) {
            const currentPath = `${path !== '' ? path + '.' + (i + 1) : (i + 1)}`;
            return (
              <div key={nextStep.id} onClick={() => { handleClickSelectedStep(nextStep) }}>
                <label htmlFor="search" className="block text-center font-small text-gray-700 cursor-pointer">
                  {`${currentPath} - ${nextStep.titulo}`}
                </label>
                <div className="rounded px-1.5 mt-1.5" style={{ backgroundColor: "#E2F7CB", width: "160px" }}>
                  <p className="text-xs mt-1 mb-2">{nextStep.mensagemInicio}</p>
                  {nextStep.opcao === 1 && Array.from({ length: 10 }, (_, j) => {
                    const campo = nextStep[`campo${j + 1}`];
                    return campo && <p className="text-xs mt-2 mb-2" key={j + 1}>{`${j + 1}`} - {campo}</p>;
                  })}
                  {nextStep.filaAtendimento === 1 && departamentos.length > 0 && departamentos.map((r : any, key : any) => {
                    console.log(r)
                    return <p className="text-xs mt-2 mb-2" key={r.id}>{`${key + 1}`} - {r.setor} ({r.horaInicio.slice(0, -3)} - {r.horaFim.slice(0, -3)})</p>;
                  })}
                  <p className="text-xs mt-1 mb-2">{nextStep.mensagemFim}</p>
                  <p className="text-right text-xs text-grey-dark mt-1">xx:xx pm</p>
                </div>
                {nextStep.opcao === 1 && (
                  <div>
                    <label htmlFor="search" className="block text-center font-small text-gray-700 cursor-pointer">
                      Invalid Message
                    </label>
                    <div className="rounded px-1.5 mt-1.5" style={{ backgroundColor: "#E2F7CB", width: "160px" }}> <> {console.log(nextStep)}</>
                      <div className="w-full justify-start">
                        <div className="rounded" style={{ backgroundColor: "#E2F7CB" }}>
                          <p className="text-xs">{nextStep.mensagemErro}</p>
                          <p className="text-right text-xs text-grey-dark mt-1">xx:xx pm</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              
              </div>
            );
          }
        }
        return null;
      }).filter((item: JSX.Element | null) => item !== null);

      if (nextSteps.length > 0) {
        renderedSteps.push(
          <li key={stepId} className={`flex-shrink-0 flex gap-x-2 cursor-pointer p-4 ${step.opcao === 1 ? 'border-dotted border-4' : ''}`}>
            {nextSteps}
          </li>
        );

        if (step.opcao === 1) {
          Array.from({ length: 10 }, (_, i) => {
            const nextStepId = step[`redirecionarSequenciaCampo${i + 1}`];
            if (nextStepId) processStep(nextStepId, `${path !== '' ? path + '.' + (i + 1) : (i + 1)}`);
          });
        }
      }
    };

    if (steps.length > 0) {
      processStep(steps[0].id, '');
    }

    return (
      <ul role="list" className="flex flex-row gap-4 p-4">
        {renderedSteps}
      </ul>
    );
  };

  const location = useLocation();
  useEffect(() => {
    setInstancia(props[0]['idInstance'])
    chatConfigApi.listBotConfigs({
      "filters": {
        "codUserInstance": instancia
      }
    }).then((response) => {
      
      setSteps(response);
      chatConfigApi.listCategoriaAtendente({
        "filters": {
          "codUserInstance": instancia
        }
      }).then((response) => {
        console.log(response);
        setDepartamentos(response);
      }); 
    });

    

     /* chatConfigApi.listAtendenteAlocado({
        "filters": {
          "codUserInstance": instancia
        }
      }).then((response) => {
        console.log(response);
        setDepartamentos(response);
      }); */
  }, []);
  return (
    <>
      <Modal
        show={showAlert}
        size={"small"}
        fullscreen={false}
        title={
          <>
            <div className='text-center text-dark'>

            </div>
          </>
        }
        list={
          <>
            <div className='text-center text-dark'>
              <div className='text-center text-dark'>
                <svg className="w-16 h-16 mx-auto text-red-600" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g fill="#ff0000"> <path d="M8 4a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 018 4zM8 10a1 1 0 100 2h.007a1 1 0 100-2H8z"></path> <path fill-rule="evenodd" d="M0 8a8 8 0 1116 0A8 8 0 010 8zm8-6.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13z" clip-rule="evenodd"></path> </g> </g></svg>

                Before adding the new step, do you want to save the changes?<br />
                <button
                  onClick={() => { handleSave(); handleClickSelectedStep([]); setShowAlert(false); }}
                  className="mt-6 rounded-lg bg-green-500 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-green-500 ring-offset-2 transition hover:bg-green-400 focus:ring-2 md:w-32"
                >
                  Yes
                </button>
                <button
                  onClick={() => { handleClickSelectedStep([]); setShowAlert(false); }}
                  className="mt-6 ml-4 rounded-lg bg-red-500 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-red-500 ring-offset-2 transition hover:bg-red-400 focus:ring-2 md:w-32"
                >
                  No
                </button>
                <button
                  onClick={() => setShowAlert(false)}
                  className="mt-6 ml-4 rounded-lg bg-yellow-500 px-4 py-2 text-center text-base font-semibold text-white shadow-md outline-none ring-yellow-500 ring-offset-2 transition hover:bg-yellow-400 focus:ring-2 md:w-32"
                >
                  Cancel
                </button>
              </div>
              <span className="visually-hidden"></span>

            </div>
          </>
        }
        footer={
          <>
            <br />
          </>
        }
      /> {console.log(steps)}
      <div className="w-screen h-full" style={{ background: "#dad3cc" }} >


        <div className="m-2 bg-white p-2 rounded-lg cursor-pointer">
          <label htmlFor="search" className="block text-center font-medium text-gray-700 cursor-pointer">
            Flow
          </label>
          <div className="w-full flex overflow-x-auto">
            <ul className='flex flex-row space-x-4'>
              {steps[0] !== undefined && (
                <li key={steps[0].id} className=" flex gap-x-2 p-4 m-4 ml-1 border-dotted border-4" onClick={() => { handleClickSelectedStep(steps[0]) }}>
                  <div>
                    <label htmlFor="search" className="block text-center font-small text-gray-700 cursor-pointer">
                      {steps[0].titulo} {'(First Contact)'}
                    </label>
                    <div className="rounded px-1.5 mt-1.5" style={{ backgroundColor: "#E2F7CB", width: "160px" }}>
                      <p className="text-xs mt-1 mb-2">{steps[0].mensagemInicio}</p>
                      {Array.from({ length: 10 }, (_, i) => {
                        const campo = steps[0][`campo${i + 1}`];
                        return campo && <p className="text-xs mt-2 mb-2" key={i + 1}>{i + 1} - {campo}</p>;
                      })}
                      <p className="text-xs mt-1 mb-2">{steps[0].mensagemFim}</p>
                      <p className="text-right text-xs text-grey-dark mt-1">xx:xx pm</p>
                    </div>
                    {steps[0].opcao === 1 && (
                      <div>
                        <label htmlFor="search" className="block text-center font-small text-gray-700 cursor-pointer">
                          Invalid Message
                        </label>
                        <div className="rounded px-1.5 mt-1.5" style={{ backgroundColor: "#E2F7CB", width: "160px" }}>
                          <div className="w-full justify-start">
                            <div className="rounded" style={{ backgroundColor: "#E2F7CB" }}>
                              <p className="text-xs">{steps[0].mensagemErro}</p>
                              <p className="text-right text-xs text-grey-dark mt-1">xx:xx pm</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </li>
              )}
              {renderSteps(steps, handleClickSelectedStep)}
            </ul>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-2 md:grid-cols-2 xl:grid-cols-4 m-2" >
          <div className="m-auto p-2 col-3 bg-white rounded-lg w-full" style={{ maxHeight: "380px", height: "380px", overflow: "auto" }}>
            <label htmlFor="search" className="block text-center font-medium text-gray-700">
              Steps
            </label>
            <div className="max-w-lg mx-auto">
              <ul className="mt-1 max-h-100 overflow-y border border-green-300 rounded-md">
                <li className={`cursor-pointer list-group-item text-center pl-3 pr-4 py-2 border-b border-green-200 w-full flex items-center hover:bg-green-100 justify-between text-gray-800 ${stepsSelected?.length === 0 ? "bg-green-100" : "bg-white"}`} onClick={() => { handleClickSelectedStep([]); }}>
                  {stepsSelected?.length === 0 ?
                    <svg style={{ color: "#16a34a" }} className="h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="current"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#000000" fill-rule="evenodd" d="M10 3a7 7 0 100 14 7 7 0 000-14zm-9 7a9 9 0 1118 0 9 9 0 01-18 0zm14 .069a1 1 0 01-1 1h-2.931V14a1 1 0 11-2 0v-2.931H6a1 1 0 110-2h3.069V6a1 1 0 112 0v3.069H14a1 1 0 011 1z"></path> </g></svg>
                    :
                    <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                  }
                  Add New Step Flow
                  <div className="px-2 ml-4 transition ease-in duration-200 text-center text-base" />
                </li>
                {steps !== undefined && steps.length > 0 ?
                  steps.map((r: any, key: number) => {
                    return (<>
                      <li className={`cursor-pointer list-group-item pl-3 pr-4 py-2 border-b border-green-200 w-full flex items-center hover:bg-green-100 justify-between text-gray-800 ${r.id === stepsSelected.id ? "bg-green-100" : "bg-white"}`} onClick={() => { setShowAlert(true); handleClickSelectedStep(r); }}>
                        {stepsSelected.id === r.id ? (
                          <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                        ) : (
                          <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                        )}
                        <p>{r.titulo} {key === 0 && '(First Contact)'}</p>
                        <button
                          type="submit" onClick={() => { handleRemoveStep(r) }}
                          className="px-2 ml-1 hover:bg-red-400 focus:ring-red-500 focus:ring-offset-red-200 text-dark transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                        >
                          <TrashIcon />
                        </button>
                      </li>
                    </>)
                  })
                  : <></>}
              </ul>
            </div>
          </div>
          <div className="m-auto p-2 col-3 bg-white rounded-lg w-full xsm:h-full sm:h-full md:h-full" style={{ maxHeight: "380px", height: "380px", overflow: "auto" }}>
            <label htmlFor="Title" className="block text-center font-medium text-gray-700">Options</label>

            {!showAtendimento && <>

                <label className="flex items-center space-x-3 cursor-pointer">
                  <input
                    type="checkbox"
                    name="checked-demo"
                    className="opacity-0 absolute h-6 w-6"
                    onClick={() => { handleCheckBoxAtendimento() }}
                  />
                  <div className="bg-white border border-green-300 rounded-md h-6 w-6 flex items-center justify-center">
                    {showOption && (
                      <svg className="fill-current text-green-500" width="22" height="22" viewBox="0 0 20 20">
                        <path d="M7.629 15.179L3.822 11.372l1.415-1.414 2.392 2.391 5.656-5.657 1.414 1.415z" />
                      </svg>

                    )}
                  </div>
                  <span className="font-normal text-gray-700 dark:text-white">Menu Mode</span>
                </label>
              <div className="max-w-lg mx-auto">

                <div>
                  <div className="flex">
                    <div className="w-64">
                      {countOptions >= 1 ?
                        <div className="relative mt-1">
                          <button
                            type="button"
                            className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            onClick={() => {
                              if (ShowSteps[0] === 0) {
                                setShowSteps([1, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                              }
                              else {
                                setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                              }
                            }
                            }
                          >
                            <span className="flex items-center">
                              <span className="block ml-3 truncate">{option1 === "" ? "Select Option 1" : option1} </span>
                            </span>

                          </button>
                          <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[0] === 1 ? "absolute" : "hidden"}`}>
                            <ul
                              role="listbox"
                              aria-labelledby="listbox-label"
                              aria-activedescendant="listbox-item-3"
                              className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                            >
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Select Option 1</span>
                                </div>

                              </li>
                              {steps !== undefined && steps.length > 0 ?
                                steps.map((r: any) => {
                                  return (<>
                                    <li
                                      id="listbox-item-0"
                                      role="option"
                                      className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                      onClick={() => { handleClickOption1(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                    >
                                      <div className="flex items-center">
                                        <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                      </div>

                                    </li></>)
                                })
                                : <></>}
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowAlert(true); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Add new Step</span>
                                </div>

                              </li>
                            </ul>
                          </div>
                        </div> : <></>}
                      {countOptions >= 2 ?

                      <>
                        <div className="relative mt-1">
                          <button
                            type="button"
                            className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            onClick={() => {
                              if (ShowSteps[1] === 0) {
                                setShowSteps([0, 1, 0, 0, 0, 0, 0, 0, 0, 0])
                              }
                              else {
                                setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                              }
                            }
                            }
                          >
                            <span className="flex items-center">
                              <span className="block ml-3 truncate">{option2 === "" ? "Select Steps 2" : option2}</span>
                            </span>

                          </button>
                          <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[1] === 1 ? "absolute" : "hidden"}`}>
                            <ul
                              role="listbox"
                              aria-labelledby="listbox-label"
                              aria-activedescendant="listbox-item-3"
                              className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"

                            >
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Select Option 2</span>
                                </div>

                              </li>
                              {steps !== undefined && steps.length > 0 ?
                                steps.map((r: any) => {
                                  return (<>
                                    <li
                                      id="listbox-item-0"
                                      role="option"
                                      className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                      onClick={() => { handleClickOption2(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                    >
                                      <div className="flex items-center">
                                        <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                      </div>

                                    </li></>)
                                })
                                : <></>}
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowAlert(true); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Add new Step</span>
                                </div>

                              </li>
                            </ul>
                          </div>
                        </div></>
                        : <></>}
                      {countOptions >= 3 ?
                        <div className="relative mt-1">
                          <button
                            type="button"
                            className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            onClick={() => {
                              if (ShowSteps[2] === 0) {
                                setShowSteps([0, 0, 1, 0, 0, 0, 0, 0, 0, 0])
                              }
                              else {
                                setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                              }
                            }
                            }
                          >
                            <span className="flex items-center">
                              <span className="block ml-3 truncate">{option3 === "" ? "Select Steps 3" : option3}</span>
                            </span>

                          </button>
                          <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[2] === 1 ? "absolute" : "hidden"}`}>
                            <ul
                              role="listbox"
                              aria-labelledby="listbox-label"
                              aria-activedescendant="listbox-item-3"
                              className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                            >
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Select Option 3</span>
                                </div>

                              </li>
                              {steps !== undefined && steps.length > 0 ?
                                steps.map((r: any) => {
                                  return (<>
                                    <li
                                      id="listbox-item-0"
                                      role="option"
                                      className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                      onClick={() => { handleClickOption3(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                    >
                                      <div className="flex items-center">
                                        <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                      </div>

                                    </li></>)
                                })
                                : <></>}
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowAlert(true); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Add new Step</span>
                                </div>

                              </li>
                            </ul>
                          </div>
                        </div>
                        : <></>}
                      {countOptions >= 4 ?
                        <div className="relative mt-1">
                          <button
                            type="button"
                            className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            onClick={() => {
                              if (ShowSteps[3] === 0) {
                                setShowSteps([0, 0, 0, 1, 0, 0, 0, 0, 0, 0])
                              }
                              else {
                                setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                              }
                            }
                            }
                          >
                            <span className="flex items-center">
                              <span className="block ml-3 truncate">{option4 === "" ? "Select Steps 4" : option4}</span>
                            </span>

                          </button>
                          <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[3] === 1 ? "absolute" : "hidden"}`}>
                            <ul
                              role="listbox"
                              aria-labelledby="listbox-label"
                              aria-activedescendant="listbox-item-3"
                              className="py-1 overflow-auto text-base max-h-32 rounded-md ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                            >
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Select Option 4</span>
                                </div>

                              </li>
                              {steps !== undefined && steps.length > 0 ?
                                steps.map((r: any) => {
                                  return (<>
                                    <li
                                      id="listbox-item-0"
                                      role="option"
                                      className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                      onClick={() => { handleClickOption4(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                    >
                                      <div className="flex items-center">
                                        <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                      </div>

                                    </li></>)
                                })
                                : <></>}
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowAlert(true); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Add new Step</span>
                                </div>

                              </li>
                            </ul>
                          </div>
                        </div>
                        : <></>}
                      {countOptions >= 5 ?
                        <div className="relative mt-1">
                          <button
                            type="button"
                            className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            onClick={() => {
                              if (ShowSteps[4] === 0) {
                                setShowSteps([0, 0, 0, 0, 1, 0, 0, 0, 0, 0])
                              }
                              else {
                                setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                              }
                            }
                            }
                          >
                            <span className="flex items-center">
                              <span className="block ml-3 truncate">{option5 === "" ? "Select Steps 5" : option5}</span>
                            </span>

                          </button>
                          <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[4] === 1 ? "absolute" : "hidden"}`}>
                            <ul
                              role="listbox"
                              aria-labelledby="listbox-label"
                              aria-activedescendant="listbox-item-3"
                              className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                            >
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Select Option 5</span>
                                </div>

                              </li>
                              {steps !== undefined && steps.length > 0 ?
                                steps.map((r: any) => {
                                  return (<>
                                    <li
                                      id="listbox-item-0"
                                      role="option"
                                      className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                      onClick={() => { handleClickOption5(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                    >
                                      <div className="flex items-center">
                                        <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                      </div>

                                    </li></>)
                                })
                                : <></>}
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowAlert(true); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Add new Step</span>
                                </div>

                              </li>
                            </ul>
                          </div>
                        </div>
                        : <></>}
                      {countOptions >= 6 ?
                        <div className="relative mt-1">
                          <button
                            type="button"
                            className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            onClick={() => {
                              if (ShowSteps[5] === 0) {
                                setShowSteps([0, 0, 0, 0, 0, 1, 0, 0, 0, 0])
                              }
                              else {
                                setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                              }
                            }
                            }
                          >
                            <span className="flex items-center">
                              <span className="block ml-3 truncate">{option6 === "" ? "Select Steps 6" : option6}</span>
                            </span>

                          </button>
                          <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[5] === 1 ? "absolute" : "hidden"}`}>
                            <ul
                              role="listbox"
                              aria-labelledby="listbox-label"
                              aria-activedescendant="listbox-item-3"
                              className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                            >
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Select Option 6</span>
                                </div>

                              </li>
                              {steps !== undefined && steps.length > 0 ?
                                steps.map((r: any) => {
                                  return (<>
                                    <li
                                      id="listbox-item-0"
                                      role="option"
                                      className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                      onClick={() => { handleClickOption6(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                    >
                                      <div className="flex items-center">
                                        <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                      </div>

                                    </li></>)
                                })
                                : <></>}
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowAlert(true); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Add new Step</span>
                                </div>

                              </li>
                            </ul>
                          </div>
                        </div>
                        : <></>}
                      {countOptions >= 7 ?
                        <div className="relative mt-1">
                          <button
                            type="button"
                            className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            onClick={() => {
                              if (ShowSteps[6] === 0) {
                                setShowSteps([0, 0, 0, 0, 0, 0, 1, 0, 0, 0])
                              }
                              else {
                                setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                              }
                            }
                            }
                          >
                            <span className="flex items-center">
                              <span className="block ml-3 truncate">{option7 === "" ? "Select Steps 7" : option7}</span>
                            </span>

                          </button>
                          <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[6] === 1 ? "absolute" : "hidden"}`}>
                            <ul
                              role="listbox"
                              aria-labelledby="listbox-label"
                              aria-activedescendant="listbox-item-3"
                              className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                            >
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Select Option 7</span>
                                </div>

                              </li>
                              {steps !== undefined && steps.length > 0 ?
                                steps.map((r: any) => {
                                  return (<>
                                    <li
                                      id="listbox-item-0"
                                      role="option"
                                      className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                      onClick={() => { handleClickOption7(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                    >
                                      <div className="flex items-center">
                                        <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                      </div>

                                    </li></>)
                                })
                                : <></>}
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowAlert(true); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Add new Step</span>
                                </div>

                              </li>
                            </ul>
                          </div>
                        </div>
                        : <></>}
                      {countOptions >= 8 ?
                        <div className="relative mt-1">
                          <button
                            type="button"
                            className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            onClick={() => {
                              if (ShowSteps[7] === 0) {
                                setShowSteps([0, 0, 0, 0, 0, 0, 0, 1, 0, 0])
                              }
                              else {
                                setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                              }
                            }
                            } >
                            <span className="flex items-center">
                              <span className="block ml-3 truncate">{option8 === "" ? "Select Steps 8" : option8}</span>
                            </span>

                          </button>
                          <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[7] === 1 ? "absolute" : "hidden"}`}>
                            <ul
                              role="listbox"
                              aria-labelledby="listbox-label"
                              aria-activedescendant="listbox-item-3"
                              className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                            >
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Select Option 8</span>
                                </div>

                              </li>
                              {steps !== undefined && steps.length > 0 ?
                                steps.map((r: any) => {
                                  return (<>
                                    <li
                                      id="listbox-item-0"
                                      role="option"
                                      className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                      onClick={() => { handleClickOption8(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                    >
                                      <div className="flex items-center">
                                        <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                      </div>

                                    </li></>)
                                })
                                : <></>}
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowAlert(true); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Add new Step</span>
                                </div>

                              </li>
                            </ul>
                          </div>
                        </div>
                        : <></>}
                      {countOptions >= 9 ?
                        <div className="relative mt-1">
                          <button
                            type="button"
                            className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            onClick={() => {
                              if (ShowSteps[8] === 0) {
                                setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 1, 0])
                              }
                              else {
                                setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                              }
                            }
                            }
                          >
                            <span className="flex items-center">
                              <span className="block ml-3 truncate">{option9 === "" ? "Select Steps 9" : option9}</span>
                            </span>

                          </button>
                          <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[8] === 1 ? "absolute" : "hidden"}`}>
                            <ul
                              role="listbox"
                              aria-labelledby="listbox-label"
                              aria-activedescendant="listbox-item-3"
                              className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                            >
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Select Option 9</span>
                                </div>

                              </li>
                              {steps !== undefined && steps.length > 0 ?
                                steps.map((r: any) => {
                                  return (<>
                                    <li
                                      id="listbox-item-0"
                                      role="option"
                                      className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                      onClick={() => { handleClickOption9(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                    >
                                      <div className="flex items-center">
                                        <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                      </div>

                                    </li></>)
                                })
                                : <></>}
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowAlert(true); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Add new Step</span>
                                </div>

                              </li>
                            </ul>
                          </div>
                        </div>
                        : <></>}
                      {countOptions >= 10 ?
                        <div className="relative mt-1">
                          <button
                            type="button"
                            className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                            onClick={() => {
                              if (ShowSteps[9] === 0) {
                                setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 1])
                              }
                              else {
                                setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                              }
                            }
                            }
                          >
                            <span className="flex items-center">
                              <span className="block ml-3 truncate">{option10 === "" ? "Select Steps 10" : option10}</span>
                            </span>

                          </button>
                          <div className={`z-10 w-full mt-1 bg-white rounded-md shadow-lg ${ShowSteps[9] === 1 ? "absolute" : "hidden"}`}>
                            <ul
                              role="listbox"
                              aria-labelledby="listbox-label"
                              aria-activedescendant="listbox-item-3"
                              className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                            >
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Select Option 10</span>
                                </div>

                              </li>
                              {steps !== undefined && steps.length > 0 ?
                                steps.map((r: any) => {
                                  return (<>
                                    <li
                                      id="listbox-item-0"
                                      role="option"
                                      className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                      onClick={() => { handleClickOption10(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                    >
                                      <div className="flex items-center">
                                        <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                      </div>

                                    </li></>)
                                })
                                : <></>}
                              <li
                                id="listbox-item-0"
                                role="option"
                                className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                onClick={() => { setShowAlert(true); }}
                              >
                                <div className="flex items-center">
                                  <span className="block ml-3 font-normal truncate">Add new Step</span>
                                </div>

                              </li>
                            </ul>
                          </div>
                        </div>
                        : <></>}
                    </div>
                    {countOptions > 0 ?
                      <button
                        type="submit" onClick={() => { handleCountOption() }}
                        className="px-2 ml-1 bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                      >
                        <PlusIcon />
                      </button> : <></>}
                    {countOptions > 1 ?
                      <button
                        type="submit" onClick={() => { handleRemoveOption() }}
                        className="px-2 ml-1 bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                      >
                        <TrashIcon />
                      </button> : <></>}
                  </div>
                </div>
                {countOptions >= 1 ?
                  <div className="">
                    <label htmlFor="Title" className="block text-center font-medium text-gray-700">Error Message</label>
                    <div className="max-w-lg mx-auto">
                      <div className=" relative ">
                        <div>
                          <textarea id="text-area"
                            className='relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 shadow-lg cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm'
                            value={mensagemErro} onChange={(e) => setMensagemErro(e.target.value)} />

                          <EmojiPicker onEmojiSelect={onEmojiSelectErro} />

                        </div>
                      </div>
                    </div>

                  </div>
                  : <></>}
              </div>
            </>}
            {!showOption && <>
              <label className="flex items-center space-x-3 cursor-pointer mt-2">
                  <input
                    type="checkbox"
                    name="checked-demo"
                    className="opacity-0 absolute h-6 w-6"
                    onClick={() => { handleAtendimento() }}
                  />
                  <div className="bg-white border border-green-300 rounded-md h-6 w-6 flex items-center justify-center">
                    {showAtendimento && (
                      <svg className="fill-current text-green-500" width="22" height="22" viewBox="0 0 20 20">
                        <path d="M7.629 15.179L3.822 11.372l1.415-1.414 2.392 2.391 5.656-5.657 1.414 1.415z" />
                      </svg>

                    )}
                  </div>
                  <span className="font-normal text-gray-700 dark:text-white">Services Mode</span>
                </label>
              <div className="max-w-lg mx-auto mt-2 table">
                

                {showAtendimento && <>
                  <label htmlFor="Title" className="block text-center font-medium text-gray-700">Departaments</label>
                  <label htmlFor="datetime-start" className="font-normal text-sm mt-2 ml-6 text-gray-700 whitespace-nowrap">
                    All Departaments
                  </label>
                  <div className="flex flex-row mr-1">
                
                  <select
                    id="countries_disabled"
                    value={departamentoSelected.id !== undefined ? departamentoSelected.id : ''}
                    onChange={
                      (e) => { 
                        var key = parseInt(e.target.value);
                        console.log(key);
                        var departamentoSelecionado = departamentos.find((d: any) => d.id === key);
                        console.log(departamentoSelecionado);
                        if(departamentoSelecionado !== undefined)
                        {
                          setDepartamentoSelected(departamentoSelecionado);
                          if(departamentoSelecionado['setor'] !== undefined){
                            setNomeDepartamento(departamentoSelecionado['setor'])
                          } 
                          if(departamentoSelecionado['horaInicio'] !== undefined){
                            setDataHoraInicio(departamentoSelecionado['horaInicio'].slice(0, -3))
                          } 
                          if(departamentoSelecionado['horaFim'] !== undefined){
                            setDataHoraFim(departamentoSelecionado['horaFim'].slice(0, -3))
                          } 
                        }
                        else
                        {
                          setDepartamentoSelected({});
                          setNomeDepartamento('');
                          setDataHoraInicio('');
                          setDataHoraFim('');
                        }
                      }}
                    className="bg-gray-50 border mr-4 border-green-300 rounded-lg py-2 px-4 ml-4 text-gray-700 placeholder-gray-400 text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent w-[240px]"
                  >
                    <option className='text-gray-400' value="">New Departament</option>
                    {departamentos.map((r: any) => {
                      return (
                      <>
                        <option value={r.id}>{r.setor} ({r.horaInicio.slice(0, -3)} - {r.horaFim.slice(0, -3)})</option>
                      </>)}
                    )}
                  </select>
                  {departamentoSelected['setor'] !== undefined &&
                    <button
                      type="submit" onClick={() => { handleRemoveDepartament(departamentoSelected) }}
                      className="px-2 h-23 w-22 hover:bg-red-400 focus:ring-red-500 focus:ring-offset-red-200 text-dark transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                    >
                      <TrashIcon />
                    </button>
                  }
                  </div>
                  <div className="max-w-lg mx-auto">

                      <label htmlFor="datetime-start" className="font-normal text-sm mt-2 ml-6 text-gray-700 whitespace-nowrap">
                        Sector Name
                      </label>
                    <div className="relative ">
                  
                      <input
                        type="text"
                        id="Title"
                        value={nomeDepartamento}
                        className="rounded-md flex-1 border w-60 ml-4 border-green-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
                        onChange={(e) => setNomeDepartamento(e.target.value)}
                      />
                    </div>
                    <div className="flex space-x-2 ml-4">
                      <div className="flex flex-col mr-1">
                        <label htmlFor="datetime-start" className="font-normal text-sm mt-2 ml-4 text-gray-700 whitespace-nowrap">
                          Time Start
                        </label>
                        <input
                          id="datetime-start"
                          type="time"
                          value={dataHoraInicio}
                          className="bg-gray-50 border text-sm text-center  border-green-300 rounded-lg xsm:ml-4 py-2 xsm:px-1 sm:px-4 md:px-4 lg:px-4 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent w-[122px]"
                          onChange={(e) => setDataHoraInicio(e.target.value)}
                          placeholder="Select date and time"
                        />
                      </div>

                      <div className="flex flex-col">
                        <label htmlFor="datetime-finish" className="font-normal text-sm mt-2 ml-4 text-gray-700 whitespace-nowrap">
                          Time Finish
                        </label>
                        <input
                          id="datetime-finish"
                          type="time"
                          value={dataHoraFim}
                          onChange={(e) => setDataHoraFim(e.target.value)}
                          className="bg-gray-50 border text-center text-sm border-green-300 rounded-lg xsm:ml-4 py-2 xsm:px-1 sm:px-4 md:px-4 lg:px-4 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent w-[122px]"
                          placeholder="Select date and time"
                        />
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="py-2 px-4 mt-2 bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                      onClick={() => { departamentoSelected['setor'] !== undefined ? handleUpdateDepartamento() : handleAddDepartamento() }}
                    >
                      Departament {departamentoSelected['setor'] !== undefined ? 'Update' : 'Insert' } 
                    </button>


                  </div>
                </>}
              </div>

            </>}
          </div>
          <div className="m-auto p-2 col-3 bg-white rounded-lg w-full" style={{ maxHeight: "380px", height: "380px", overflow: "auto" }}>
            <label htmlFor="Title" className="block text-center font-medium text-gray-700">Title</label>
            <div className="max-w-lg mx-auto">
              <div className="relative ">
                <input
                  type="text"
                  id="Title"
                  value={titulos}
                  className="rounded-md flex-1 border border-green-300 w-full py-1 px-4 bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
                  onChange={(e) => setTitulos(e.target.value)}
                />
              </div>
            </div>
            <label htmlFor="Title" className="block text-center font-medium text-gray-700 py-1">Start Message</label>
            <div className="max-w-lg mx-auto">

              <textarea id="text-area" className='relative w-full py-1 pl-3 pr-10 text-left bg-white rounded border border-green-300 cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm'
                value={mensagemsInicio}
                onFocus={() => setEditing('inicio')}
                onKeyDown={handleKeyDown}
                onChange={handleChangeInicio}
              />

              <EmojiPicker onEmojiSelect={onEmojiSelectInicio} />
            </div>
            <label htmlFor="Title" className="block text-center font-medium text-gray-700">End Message</label>
            <div className=" max-w-lg mx-auto">
              <textarea id="text-area" className='relative w-full py-1 pl-3 pr-10 text-left bg-white rounded border border-green-300 cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm'
                value={mensagemsFim}
                onFocus={() => setEditing('fim')}
                onKeyDown={handleKeyDown}
                onChange={handleChangeFim} />
              <EmojiPicker onEmojiSelect={onEmojiSelectFim} />
            </div>
              <button
                type="submit"
                className="py-2 px-4 bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                onClick={() => { handleSave() }}
              >
                Save
              </button>
          </div>

          <div className="m-auto p-2 col-3 bg-white rounded-lg w-full " style={{ maxHeight: "380px", height: "380px", overflow: "auto" }}>
            <div className="w-full justify-start " >
              <label htmlFor="search" className="block text-center font-medium text-gray-700">
                Response Message
              </label>
              <div
                className="rounded px-3 mt-3 table"
                style={{ backgroundColor: "#E2F7CB", width: "250px" }}
              >
                <p className="text-sm mt-1 mb-2 ">{mensagemsInicio}</p>
                
                {showAtendimento && departamentos.length > 0 && departamentos.map((r : any, key : any) => {
                  return <p className="text-xs mt-2 mb-2" key={r.id}>{`${key + 1}`} - {r.setor} ({r.horaInicio.slice(0, -3)} - {r.horaFim.slice(0, -3)})</p>;
                })}
                {option1 !== "" ? <p className="text-sm mt-2 mb-2">1 - {option1}</p> : <></>}
                {option2 !== "" ? <p className="text-sm mt-2 mb-2">2 - {option2}</p> : <></>}
                {option3 !== "" ? <p className="text-sm mt-2 mb-2">3 - {option3}</p> : <></>}
                {option4 !== "" ? <p className="text-sm mt-2 mb-2">4 - {option4}</p> : <></>}
                {option5 !== "" ? <p className="text-sm mt-2 mb-2">5 - {option5}</p> : <></>}
                {option6 !== "" ? <p className="text-sm mt-2 mb-2">6 - {option6}</p> : <></>}
                {option7 !== "" ? <p className="text-sm mt-2 mb-2">7 - {option7}</p> : <></>}
                {option8 !== "" ? <p className="text-sm mt-2 mb-2">8 - {option8}</p> : <></>}
                {option9 !== "" ? <p className="text-sm mt-2 mb-2">9 - {option9}</p> : <></>}
                {option10 !== "" ? <p className="text-sm mt-2 mb-2">10 - {option10}</p> : <></>}
                
                <p className="text-sm mt-1 mb-2 ">{mensagemsFim}</p>
                <p className="text-right text-xs text-grey-dark mt-1">
                  xx:xx pm
                </p>
              </div>
              
              {countOptions >= 1 ?
                <div className="w-full justify-start mt-2"><br />
                  <label htmlFor="search" className="block text-center font-medium text-gray-700">
                    Option Invalid Message
                  </label><br />
                  <div
                    className="rounded px-3 mt-3"
                    style={{ backgroundColor: "#E2F7CB", width: "250px" }}
                  >
                    <p className="text-sm">{mensagemErro}</p>
                    <p className="text-right text-xs text-grey-dark mt-1">
                      xx:xx pm
                    </p>
                  </div>

                </div> : <></>}
            </div>

          </div>
        </div>

      </div>

    </>
  );


}
export default BotConfig;
