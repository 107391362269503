import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Payment() {

    let navigate = useNavigate();


    useEffect(() => {


    }, []);
    return (
        <>

            <div className="h-screen">
                <div className="relative mx-auto w-screen" style={{ background: "#dad3cc" }}>
                    <div className="absolute inset-0 bottom-20 bg-gradient-to-t from-green-900 to-green-700 border-green-700" />
                    <div className="relative mx-auto w-full max-w-screen-xl px-4 pt-6 sm:px-6">
                        <nav className="text-xs sm:text-sm text-white rounded-md">
                            <ol className="list-none p-0 inline-flex space-x-2">
                                <li className="flex items-center text-white">
                                    <svg
                                        onClick={() => { window.location.href = '/'; }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 576 512"
                                        className="cursor-pointer hover:fill-green-500 transition-colors duration-300"
                                        fill="white"
                                    >
                                        {/* Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                                        <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
                                    </svg>{" "}
                                    <svg
                      className="w-6 h-6 text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                                </li>
                                <li className="flex items-center text-white ">
                                    <button
                                        onClick={() => navigate("/sign-up")}
                                        className="text-white hover:text-green-500 transition-colors duration-300"
                                    >
                                        Sign Up
                                    </button>
                                    <svg
                      className="w-6 h-6 text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                                </li>
                                <li className="flex items-center text-white ">
                                    <button
                                        onClick={() => navigate("/pricing")}
                                        className="text-white hover:text-green-500 transition-colors duration-300"
                                    >
                                        Pricing
                                    </button>
                                    <svg
                      className="w-6 h-6 text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                                </li>
                                <li className="flex items-center text-white ">
                                    <button
                                        onClick={() => navigate("/pricing")}
                                        className="text-white hover:text-green-500 transition-colors duration-300"
                                    >
                                        Plan
                                    </button>
                                </li>
                            </ol>
                        </nav>
                        <div className="mx-auto w-full max-w-md pb-10 px-8 sm:px-0">
                            <div className="relative mt-5">
                                <div
                                    className="absolute left-0 top-2 h-0.5 w-full bg-gray-200"
                                    aria-hidden="true"
                                >
                                    <div className="absolute h-full w-1/2 bg-gray-900" />
                                    <div className="left absolute left-1/2 h-full w-1/3 bg-gradient-to-r from-gray-900" />
                                </div>
                                <ul className="relative flex w-full justify-between">
                                    <li className="text-left">
                                        <a
                                            className="flex h-5 w-5 items-center justify-center rounded-full bg-green-400 text-xs font-semibold text-white"
                                        >
                                            1
                                        </a>
                                    </li>
                                    <li className="text-left">
                                        <a
                                            className="flex h-5 w-5 items-center justify-center rounded-full bg-green-400 text-xs font-semibold text-white"
                                        >
                                            2
                                        </a>
                                    </li>
                                    <li className="text-left">
                                        <a
                                            className="flex h-5 w-5 items-center justify-center rounded-full bg-gray-300 text-xs font-semibold text-white ring ring-green-600 ring-offset-2"
                                        >
                                            3
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="w-full bg-white border-t border-b border-gray-200 px-5 py-10 text-gray-800">
                            <div className="w-full">
                                <div className="-mx-3 md:flex items-start">
                                    <div className="px-3 md:w-7/12 lg:pr-10">
                                        <div className="w-full mx-auto text-gray-800 font-light mb-6 border-b border-gray-200 pb-6">
                                            <div className="w-full flex items-center">
                                                {/* <div className="overflow-hidden rounded-lg w-16 h-16 bg-gray-50 border border-gray-200">
                                                    <img
                                                        src="https://images.unsplash.com/photo-1572635196237-14b3f281503f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1160&q=80"
                                                        alt=""
                                                    />
                                                </div> */}
                                                <div className="flex-grow pl-3">
                                                    <h6 className="font-semibold  text-gray-600">
                                                        BASIC PLAN <span className="font-semibold text-gray-400"> / Mounth</span>
                                                    </h6>
                                                </div>
                                                <div>
                                                    <span className="font-semibold text-gray-600 text-xl">
                                                        $19
                                                    </span>
                                                    <span className="font-semibold text-gray-600 text-sm">
                                                        .00
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-2 pb-2 border-b border-gray-200">
                                            <div className="-mx-2 flex items-end justify-end">
                                                <div className="flex-grow px-2 lg:max-w-xs">
                                                    <label className="text-gray-600 font-semibold text-sm mb-2 ml-1">
                                                         code
                                                    </label>
                                                    <div>
                                                        <input
                                                            className="w-full px-3 py-2 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
                                                            placeholder="XXXXXX"
                                                            type="text"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="px-2">
                                                    <button className="block w-full max-w-xs mx-auto border border-transparent bg-gray-400 hover:bg-gray-500 focus:bg-gray-500 text-white rounded-md px-5 py-2 font-semibold">
                                                        APPLY
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full flex-1 p-4">
                                        <ul className="list-inside list-disc divide-y text-sm">
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">WhatsApp Integration.</p>
                                            </li>
                                            <li className="flex items-start py-2 text-left">
                                            <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">24/7 Chatbot Availability</p>
                                            </li>
                                            <li className="flex items-start py-2 text-left">
                                            <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">Automatic Responses to Frequently Asked Questions</p>
                                            </li>
                                            <li className="flex items-start py-2 text-left">
                                            <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">Instant Customer Support.</p>
                                               
                                            </li>
                                        </ul>
                                    </div>
                                        <div className=" border-b border-gray-200 text-gray-800">
                                            <div className="w-full flex mb-3 items-center">
                                                <div className="flex-grow">
                                                    <span className="text-gray-600">Subtotal</span>
                                                </div>
                                                <div className="pl-3">
                                                    <span className="font-semibold">$19.00</span>
                                                </div>
                                            </div>
                                            
                                        </div>
                                        <div className="mb-2 pb-2 border-b border-gray-200 md:border-none text-gray-800 text-xl">
                                            <div className="w-full flex items-center">
                                                <div className="flex-grow">
                                                    <span className="text-gray-600">Total</span>
                                                </div>
                                                <div className="pl-3">
                                                    <span className="font-semibold text-gray-400 text-sm">
                                                        USD
                                                    </span>{" "}
                                                    <span className="font-semibold">$19.00</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="px-3 md:w-5/12">
                                        {/* <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 p-3 text-gray-800 font-light mb-6">
                                            <div className="w-full flex mb-3 items-center">
                                                <div className="w-32">
                                                    <span className="text-gray-600 font-semibold">Contact</span>
                                                </div>
                                                <div className="flex-grow pl-3">
                                                    <span>Scott Windon</span>
                                                </div>
                                            </div>
                                            <div className="w-full flex items-center">
                                                <div className="w-32">
                                                    <span className="text-gray-600 font-semibold">
                                                        Billing Address
                                                    </span>
                                                </div>
                                                <div className="flex-grow pl-3">
                                                    <span>123 George Street, Sydney, NSW 2000 Australia</span>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="w-full mx-auto rounded-lg bg-white border border-gray-200 text-gray-800 font-light mb-6">
                                            <div className="w-full p-3 border-b border-gray-200">
                                                <div className="mb-5">
                                                    <label
                                                        htmlFor="type1"
                                                        className="flex items-center cursor-pointer"
                                                    >
                                                        <input
                                                            type="radio"
                                                            className="form-radio h-5 w-5 text-indigo-500"
                                                            name="type"
                                                            id="type1"
                                                        />
                                                        <img
                                                            src="https://leadershipmemphis.org/wp-content/uploads/2020/08/780370.png"
                                                            className="h-6 ml-3"
                                                        />
                                                    </label>
                                                </div>
                                                <div>
                                                    <div className="mb-3">
                                                        <label className="text-gray-600 font-semibold text-sm mb-2 ml-1">
                                                            Name on card
                                                        </label>
                                                        <div>
                                                            <input
                                                                className="w-full px-3 py-2 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
                                                                placeholder="John Smith"
                                                                type="text"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <label className="text-gray-600 font-semibold text-sm mb-2 ml-1">
                                                            Card number
                                                        </label>
                                                        <div>
                                                            <input
                                                                className="w-full px-3 py-2 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
                                                                placeholder="0000 0000 0000 0000"
                                                                type="text"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-3 -mx-2 flex items-end">
                                                        <div className="px-2 w-1/4">
                                                            <label className="text-gray-600 font-semibold text-sm mb-2 ml-1">
                                                                Expiration date
                                                            </label>
                                                            <div>
                                                                <select className="form-select w-full px-3 py-2 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors cursor-pointer">
                                                                    <option value={'01'}>January</option>
                                                                    <option value={'02'}>February</option>
                                                                    <option value={'03'}>March</option>
                                                                    <option value={'04'}>April</option>
                                                                    <option value={'05'}>May</option>
                                                                    <option value={'06'}>June</option>
                                                                    <option value={'07'}>July</option>
                                                                    <option value={'08'}>August</option>
                                                                    <option value={'09'}>September</option>
                                                                    <option value={'10'}>October</option>
                                                                    <option value={'11'}>November</option>
                                                                    <option value={'12'}>December</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div className="px-2 w-1/4">
                                                            <select className="form-select w-full px-3 py-2 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors cursor-pointer">
                                                                <option value={2020}>2020</option>
                                                                <option value={2021}>2021</option>
                                                                <option value={2022}>2022</option>
                                                                <option value={2023}>2023</option>
                                                                <option value={2024}>2024</option>
                                                                <option value={2025}>2025</option>
                                                                <option value={2026}>2026</option>
                                                                <option value={2027}>2027</option>
                                                                <option value={2028}>2028</option>
                                                                <option value={2029}>2029</option>
                                                            </select>
                                                        </div>
                                                        <div className="px-2 w-1/4">
                                                            <label className="text-gray-600 font-semibold text-sm mb-2 ml-1">
                                                                Security code
                                                            </label>
                                                            <div>
                                                                <input
                                                                    className="w-full px-3 py-2 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
                                                                    type="text"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-full p-3">
                                                <label
                                                    htmlFor="type2"
                                                    className="flex items-center cursor-pointer"
                                                >
                                                    <input
                                                        type="radio"
                                                        className="form-radio h-5 w-5 text-indigo-500"
                                                        name="type"
                                                        id="type2"
                                                    />
                                                    <img
                                                        src="https://upload.wikimedia.org/wikipedia/commons/b/b5/PayPal.svg"
                                                        width={80}
                                                        className="ml-3"
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                        <div>
                                            <button onClick={() => navigate("/chat")} className="block w-full max-w-xs mx-auto bg-green-500 hover:bg-green-400 focus:bg-green-700 text-white rounded-lg px-3 py-2 font-semibold">
                                                <i className="mdi mdi-lock-outline mr-1" /> PAY NOW
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-5">
                            <div className="text-center text-gray-400 text-sm">
                                <button
                                    className="focus:outline-none underline text-gray-400"
                                >
                                    <i className="mdi mdi-beer-outline" />
                                    Buy me 
                                </button>{" "}
                                and help support open-resource
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </>

    );


}
export default Payment;