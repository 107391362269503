import React, { useState, useRef, useEffect } from 'react';

type EmojiPickerProps = {
  onEmojiSelect: (emoji: string) => void;
};

const EmojiPicker: React.FC<EmojiPickerProps> = ({ onEmojiSelect }) => {
  const [isNavbarVisible, setIsNavbarVisible] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const ref = useRef<HTMLDivElement>(null);

  const toggleNavbar = () => {
    setIsNavbarVisible(!isNavbarVisible);
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCategory(e.target.value);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsNavbarVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const emojiCategories: { [key: string]: string[] } = {
    'Smileys & Emotion': [
      "😀", "😂", "😍", "😭", "😡", "🥰", "😱", "🤔", "😴", "🤯", "🥳", "😓", "😉", "🙃", "😇",
      "😃", "😄", "😁", "😆", "😅", "🤣", "😊", "😋", "😎", "🤓", "😜", "😝", "😛", "🤗", "🤩",
      "😤", "😠", "🤬", "😈", "👿", "💀", "☠️", "🤡", "💩", "👻", "👽", "👾", "🤖", "🎃", "😺"
    ],
    'People & Body': [
      "👍", "💪", "👏", "🙏", "💅", "🤳", "👫", "🧑‍🤝‍🧑", "🧘", "🚶", "👷‍♂️", "🕵️‍♀️", "💂‍♂️", "👩‍⚕️", "👨‍🍳",
      "🙍‍♂️", "🙍‍♀️", "🙎‍♂️", "🙎‍♀️", "🙅‍♂️", "🙅‍♀️", "🙆‍♂️", "🙆‍♀️", "💁‍♂️", "💁‍♀️", "🙋‍♂️", "🙋‍♀️", "🧏‍♂️", "🧏‍♀️", "🙇‍♂️",
      "🙇‍♀️", "🤦‍♂️", "🤦‍♀️", "🤷‍♂️", "🤷‍♀️", "👨‍⚖️", "👩‍⚖️", "👨‍🌾", "👩‍🌾", "👨‍🍳", "👩‍🍳", "👨‍🎓", "👩‍🎓", "👨‍🎤", "👩‍🎤"
    ],
    'Animals & Nature': [
      "🚀", "🌳", "🐶", "🐱", "🦄", "🦋", "🐝", "🌹", "🌍", "🪴", "🌵", "🍁", "🍄", "🐉", "🦕",
      "🐀", "🐁", "🐭", "🐹", "🐰", "🐇", "🐿️", "🦔", "🦇", "🐻", "🐨", "🐼", "🦥", "🦦", "🦨",
      "🦘", "🦡", "🐾", "🐉", "🐲", "🌵", "🎄", "🌲", "🌳", "🌴", "🌱", "🌿", "☘️", "🍀", "🎍"
    ],
    'Food & Drink': [
      "🍎", "🍕", "🍔", "🍣", "🍺", "🥑", "🍰", "☕", "🍜", "🍌", "🥥", "🧀", "🍖", "🍩", "🍪",
      "🍉", "🍇", "🍓", "🍈", "🍒", "🍑", "🍍", "🥭", "🍆", "🥔", "🥕", "🌽", "🌶️", "🫑", "🥒",
      "🥬", "🥦", "🧄", "🧅", "🍄", "🥜", "🌰", "🍞", "🥖", "🥨", "🥯", "🥞", "🧇", "🧈", "🍗"
    ],
    'Travel & Places': [
      "🚗", "✈️", "🚉", "🏝️", "🏔️", "🗽", "🏰", "🛤️", "🌆", "🚢", "🌉", "🎡", "🏜️", "🌋", "🗼",
      "🏠", "🏡", "🏘️", "🏚️", "🏢", "🏬", "🏣", "🏤", "🏥", "🏦", "🏨", "🏪", "🏫", "🏩", "💒",
      "🏛️", "⛪", "🕌", "🕍", "🛕", "🕋", "⛲", "⛺", "🌁", "🌃", "🏙️", "🌄", "🌅", "🌆", "🌇"
    ],
    'Activities': [
      "⚽", "🏀", "🏈", "🎾", "🎮", "🎲", "🏋️‍♂️", "🚴‍♀️", "🏊‍♂️", "🧗‍♀️", "🎣", "🏹", "🎿", "🛹", "🏎️",
      "🏇", "🏅", "🥇", "🥈", "🥉", "🏆", "🎽", "🎳", "🎯", "🎱", "🎟️", "🎫", "🎭", "🎨", "🎬",
      "🎤", "🎧", "🎼", "🎹", "🥁", "🎷", "🎺", "🎸", "🎻", "🎲", "🎮", "🎰", "🎲", "🎯", "🎳"
    ],
    'Objects': [
      "💻", "📱", "📸", "🎥", "🔑", "🎒", "🕰️", "🔦", "🧲", "🔔", "📚", "🔨", "🛠️", "🧵", "🧶",
      "🖥️", "🖨️", "🖱️", "🖲️", "🕹️", "💽", "💾", "💿", "📀", "📼", "📷", "📹", "📼", "🔍", "🔎",
      "🗂️", "🗃️", "🗄️", "🗑️", "🛒", "💸", "💵", "💴", "💶", "💷", "💰", "💳", "🧾", "📜", "📃"
    ],
    'Symbols': [
      "❤️", "✨", "🔥", "🎵", "💲", "🔒", "🚫", "✔️", "♻️", "💯", "⚠️", "🚸", "📵", "♾️", "🕉️",
      "☮️", "✝️", "☪️", "🕉️", "☸️", "✡️", "🔯", "🕎", "☯️", "☦️", "🛐", "⛎", "♈", "♉", "♊",
      "♋", "♌", "♍", "♎", "♏", "♐", "♑", "♒", "♓", "⛎", "🔀", "🔁", "🔂", "⏩", "⏭️"
    ], 'Flags': [
      "🏁", "🚩", "🇺🇸", "🇬🇧", "🇯🇵", "🇩🇪", "🇧🇷", "🇮🇳", "🇨🇳", "🇫🇷", "🇨🇦", "🇦🇺", "🇮🇹", "🇷🇺", "🇪🇸",
      "🇦🇫", "🇦🇱", "🇩🇿", "🇦🇩", "🇦🇴", "🇦🇷", "🇦🇲", "🇦🇹", "🇦🇿", "🇧🇸", "🇧🇭", "🇧🇩", "🇧🇧", "🇧🇾", "🇧🇪",
      "🇧🇿", "🇧🇯", "🇧🇹", "🇧🇴", "🇧🇼", "🇧🇳", "🇧🇬", "🇧🇫", "🇧🇮", "🇨🇻", "🇰🇭", "🇨🇲", "🇨🇫", "🇹🇩", "🇨🇱"
    ]
  };

  return (
    <div className="d-flex" ref={ref}>
      <button
        type="button"
        onClick={toggleNavbar}
        className="inline-flex items-center justify-center rounded-full h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none"
      >
        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM8.39747 15.5534C8.64413 15.2206 9.11385 15.1508 9.44661 15.3975C10.175 15.9373 11.0541 16.25 12 16.25C12.9459 16.25 13.825 15.9373 14.5534 15.3975C14.8862 15.1508 15.3559 15.2206 15.6025 15.5534C15.8492 15.8862 15.7794 16.3559 15.4466 16.6025C14.4742 17.3233 13.285 17.75 12 17.75C10.715 17.75 9.5258 17.3233 8.55339 16.6025C8.22062 16.3559 8.15082 15.8862 8.39747 15.5534Z" fill="#1C274C" />
          <path d="M16 10.5C16 11.3284 15.5523 12 15 12C14.4477 12 14 11.3284 14 10.5C14 9.67157 14.4477 9 15 9C15.5523 9 16 9.67157 16 10.5Z" fill="#1C274C" />
          <path d="M10 10.5C10 11.3284 9.55229 12 9 12C8.44772 12 8 11.3284 8 10.5C8 9.67157 8.44772 9 9 9C9.55229 9 10 9.67157 10 10.5Z" fill="#1C274C" />
        </svg>
      </button>
      {isNavbarVisible && (
        <div className="absolute mt-2 w-72 bg-white border border-gray-300 rounded-lg shadow-lg p-4 z-10" style={{ top: selectedCategory === "" ? "auto" : "20px", maxHeight: '300px', overflowY: 'auto' }}>
          <div className="fixed">
            <select
              onChange={handleCategoryChange}
              className="p-10 py-2 pl-3 pr-10 text-left bg-white rounded-md shadow-md cursor-pointer focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
              value={selectedCategory}
            >
              <option value="">Selecione uma Categoria</option>
              {Object.keys(emojiCategories).map((category) => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>
          <div className="mt-10 grid grid-cols-5 gap-2">
            {selectedCategory && emojiCategories[selectedCategory]?.map((emoji: string) => (
              <button
                key={emoji}
                onClick={() => onEmojiSelect(emoji)}
                className="text-xl p-2 hover:bg-gray-200 rounded-md"
              >
                {emoji}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default EmojiPicker;