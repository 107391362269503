import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function Pricing() {

    let navigate = useNavigate();
    const handlePlanBasic = () => {
        
        navigate('/pricing-plan')

    };

    useEffect(() => {


    }, []);
    return (
        <>
            <div className="h-screen">
                <div className="relative mx-auto w-screen" style={{ background: "#dad3cc" }}>
                    <div className="absolute inset-0 bottom-20 bg-gradient-to-t from-green-900 to-green-700 border-green-700" />
                    <div className="relative mx-auto w-full max-w-screen-xl px-4 pt-4 sm:px-6">
                        <nav className=" ml-6 text-xs sm:text-sm text-white rounded-md">
                            <ol className="list-none p-0 inline-flex space-x-2">
                                <li className="flex items-center text-white">
                                    <svg
                                        onClick={() => { window.location.href = '/'; }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 576 512"
                                        className="cursor-pointer hover:fill-green-500 transition-colors duration-300"
                                        fill="white"
                                    >
                                        {/* Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. */}
                                        <path d="M575.8 255.5c0 18-15 32.1-32 32.1h-32l.7 160.2c0 2.7-.2 5.4-.5 8.1V472c0 22.1-17.9 40-40 40H456c-1.1 0-2.2 0-3.3-.1c-1.4 .1-2.8 .1-4.2 .1H416 392c-22.1 0-40-17.9-40-40V448 384c0-17.7-14.3-32-32-32H256c-17.7 0-32 14.3-32 32v64 24c0 22.1-17.9 40-40 40H160 128.1c-1.5 0-3-.1-4.5-.2c-1.2 .1-2.4 .2-3.6 .2H104c-22.1 0-40-17.9-40-40V360c0-.9 0-1.9 .1-2.8V287.6H32c-18 0-32-14-32-32.1c0-9 3-17 10-24L266.4 8c7-7 15-8 22-8s15 2 21 7L564.8 231.5c8 7 12 15 11 24z" />
                                    </svg>{" "}
                                    <svg
                                        className="w-6 h-6 text-white"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            
                                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </li>
                                <li className="flex items-center text-white ">
                                    <button
                                        onClick={() => navigate("/sign-up")}
                                        className="text-white hover:text-green-500 transition-colors duration-300"
                                    >
                                        Sign Up
                                    </button>
                                    <svg
                                        className="w-6 h-6 text-white"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            
                                            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                </li>
                                <li className="flex items-center text-white ">
                                    <button
                                        onClick={() => navigate("/pricing")}
                                        className="text-white hover:text-green-500 transition-colors duration-300"
                                    >
                                        Pricing
                                    </button>
                                </li>
                            </ol>
                        </nav>
                        <div className="mx-auto w-full max-w-md pb-10 px-8 sm:px-0">
                            <div className="relative mt-5">
                                <div
                                    className="absolute left-0 top-2 h-0.5 w-full bg-gray-200"
                                    aria-hidden="true"
                                >
                                    <div className="absolute h-full w-1/2 bg-gray-900" />
                                    {/* change to w-2/3 for next step */}
                                    <div className="left absolute left-1/2 h-full w-1/3 bg-gradient-to-r from-gray-200" />
                                    {/* change to left-1/2 for next step */}
                                </div>
                                <ul className="relative flex w-full justify-between">
                                    <li className="text-left">
                                        <a
                                            className="flex h-5 w-5 items-center justify-center rounded-full bg-green-400 text-xs font-semibold text-white"
                                        >
                                            1
                                        </a>
                                    </li>
                                    <li className="text-left">
                                        <a
                                            className="flex h-5 w-5 items-center justify-center rounded-full bg-green-400 text-xs font-semibold text-white ring ring-green-600 ring-offset-2"
                                        >
                                            2
                                        </a>
                                    </li>
                                    <li className="text-left">
                                        <a
                                            className="flex h-5 w-5 items-center justify-center rounded-full bg-gray-300 text-xs font-semibold text-white"
                                        >
                                            3
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>


                        <div className="mb-6">
                            <h1 className="text-center text-lg font-bold text-white sm:text-2xl">
                                Reduced Costs.
                            </h1>
                            <h1 className="mx-auto mt-3 text-xs max-w-md text-center text-white sm:text-sm">
                                Our system offers efficiency, cost savings, scalability, and an enhanced customer service experience, making it a powerful choice for businesses looking to improve their service and save resources.
                            </h1>
                            {/* <button className="mx-auto mt-4 flex items-center rounded-lg border border-black bg-green-600 px-3 py-1 font-semibold text-white hover:border-white hover:bg-green-700">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mr-2 h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            
                            strokeLinejoin="round"
                            d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z"
                        />
                    </svg>
                    Talk to Sales
                </button> */}
                        </div>
                        <div className="mx-auto grid max-w-screen grid-cols-1 gap-4 py-4 px-4 sm:grid-cols-2 lg:grid-cols-4">
                            <div className="group rounded-md bg-white p-0 text-left text-green-900 shadow-xl shadow-black/5 transition-transform hover:-translate-y-2">
                                <button
                                    onClick={() => handlePlanBasic()}
                                    rel="noreferrer noopener"
                                    className="flex h-full cursor-pointer select-none flex-col items-center rounded-md"
                                >
                                    <div className="w-full p-4">
                                        <h3 className="text-2xl font-black text-green-700">Basic</h3>
                                    </div>
                                    <div className="w-full px-4 pb-2">
                                        <p className="text-2xl font-black leading-none">
                                            <span className="text-xs">$</span>19
                                            <span className="text-base font-normal">/month</span>
                                        </p>
                                    </div>
                                    <div className="w-full flex-1 p-4">
                                        <ul className="list-inside list-disc divide-y text-sm">
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">WhatsApp Integration.</p>
                                            </li>
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">24/7 Chatbot Availability</p>
                                            </li>
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">Automatic Responses to Frequently Asked Questions</p>
                                            </li>
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">Instant Customer Support.</p>

                                            </li>
                                        </ul>
                                    </div>
                                    <div className="w-full px-4 pb-4">
                                        <p className="group flex w-full items-center justify-center rounded py-1 text-center font-bold transition group-hover:bg-green-700 group-hover:text-white">
                                            Select
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="ml-4 h-4 w-4"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    
                                                    strokeLinejoin="round"
                                                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                />
                                            </svg>
                                        </p>
                                    </div>
                                </button>
                            </div>
                            <div className="group rounded-md bg-white p-0 text-left text-green-900 shadow-xl shadow-black/5 transition-transform hover:-translate-y-2">
                                <a
                                    rel="noreferrer noopener"
                                    className="flex h-full cursor-pointer select-none flex-col items-center rounded-md"
                                >
                                    <div className="w-full p-4">
                                        <h3 className="text-2xl font-black text-green-700">Team</h3>
                                    </div>
                                    <div className="w-full px-4">
                                        <p className="text-2xl font-black leading-none">
                                            <span className="text-xs">$</span>49
                                            <span className="text-base font-normal">/month</span>
                                        </p>
                                    </div>
                                    <div className="w-full flex-1 p-4">
                                        <ul className="list-inside list-disc divide-y text-sm">
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">All functionalities of the Basic Plan</p>
                                            </li>
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">
                                                    More Integration with Facebook, Instagram, and Twitter
                                                </p>
                                            </li>
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">
                                                    Valuable Customer Behavior Data Collection
                                                </p>
                                            </li>
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">
                                                    Invite up to 4 team members
                                                </p>
                                            </li>
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">Humanized Customer Support Workflow with Intelligent Queue Management</p>

                                            </li>
                                        </ul>
                                    </div>
                                    <div className="w-full px-4 pb-4">
                                        <p className="group flex w-full items-center justify-center rounded bg-gray-300 py-1 text-center font-bold text-white transition">
                                            Not available yet
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="ml-4 h-4 w-4"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    
                                                    strokeLinejoin="round"
                                                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                />
                                            </svg>
                                        </p>
                                    </div>
                                </a>
                            </div>
                            <div className="group rounded-md bg-white p-0 text-left text-green-900 shadow-xl shadow-black/5 transition-transform hover:-translate-y-2">
                                <a
                                    rel="noreferrer noopener"
                                    className="flex h-full cursor-pointer select-none flex-col items-center rounded-md"
                                >
                                    <div className="w-full p-4">
                                        <h3 className="text-2xl font-black text-green-700">Company</h3>
                                    </div>
                                    <div className="w-full px-4 pb-2">
                                        <p className="text-2xl font-black leading-none">
                                            <span className="text-xs">$</span>129
                                            <span className="text-base font-normal">/month</span>
                                        </p>
                                    </div>
                                    <div className="w-full flex-1 p-4">
                                        <ul className="list-inside list-disc divide-y text-sm">
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">All features of the Basic and Team Plans</p>
                                            </li>
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">Invite up to 12 team members</p>
                                            </li>
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">More Integration with ChatGPT 4.0, Amazon, and Mercado Livre</p>
                                            </li>
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">Integration with CRM systems and other business tools</p>
                                            </li>
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">Customized interactions with tailored recommendations</p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="w-full px-4 pb-4">
                                        <p className="group flex w-full items-center justify-center rounded bg-gray-300 py-1 text-center font-bold text-white transition">
                                            Not available yet
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="ml-4 h-4 w-4"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    
                                                    strokeLinejoin="round"
                                                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                />
                                            </svg>
                                        </p>
                                    </div>
                                </a>
                            </div>
                            <div className="group rounded-md bg-white p-0 text-left text-green-900 shadow-xl shadow-black/5 transition-transform hover:-translate-y-2">
                                <a
                                    rel="noreferrer noopener"
                                    className="flex h-full cursor-pointer select-none flex-col items-center rounded-md"
                                >
                                    <div className="w-full p-4">
                                        <h3 className="text-2xl font-black text-green-700">Custom</h3>
                                    </div>
                                    <div className="w-full px-4 pb-2">
                                        <p className="text-2xl font-black leading-none">Get a Quote</p>
                                    </div>
                                    <div className="w-full flex-1 p-4">
                                        <ul className="list-inside list-disc divide-y text-sm">
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">All features of the previous plans</p>
                                            </li>
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">Unlimited team member invitations</p>
                                            </li>
                                            <li className="flex items-start py-2 text-left">
                                                <svg
                                                    className="w-6 h-6 mr-2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width={6}
                                                    height={6}
                                                    stroke="currentColor"
                                                    fill="#10b981"
                                                    viewBox="0 0 1792 1792"
                                                >
                                                    <path d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"></path>
                                                </svg>
                                                <p className="w-full">
                                                    Advanced customization to meet specific business needs
                                                </p>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="w-full px-4 pb-4">
                                        <p className="group flex w-full items-center justify-center rounded bg-gray-300 py-1 text-center font-bold text-white transition">
                                            Not available yet
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="ml-4 h-4 w-4"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                                strokeWidth={2}
                                            >
                                                <path
                                                    
                                                    strokeLinejoin="round"
                                                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                                                />
                                            </svg>
                                        </p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );


}
export default Pricing;